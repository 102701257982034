@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");

#aboutPic {
  height: auto;
  width: 95%;
  box-shadow: 10px 10px 8px black;
  border: 5px solid rgb(3, 235, 243);
  border-style: ridge;
  outline: 5px solid purple;
  outline-style: groove;
  border-width: 8px;
  margin: 10px;
}

#policyButton {
  background: -webkit-linear-gradient(top, grey, black);
  border: 2px solid rgba(49, 49, 49, 0.795);
  border-style: outset;
  box-shadow: 2px 2px 2px rgb(66, 65, 65);
  text-shadow: 1px 1px black;
}

#policyButton1 {
  background: -webkit-linear-gradient(bottom, navy, rgb(3, 235, 243));
  border: 2px solid rgba(49, 49, 49, 0.795);
  border-style: outset;
  box-shadow: 2px 2px 2px rgb(66, 65, 65);
  text-shadow: 1px 1px black;
}

#policyList {
  border-radius: 1%/5%;
  list-style-type: square;
  padding-left: 12px;
}

#policyCert {
  margin: 1px;
  background: -webkit-linear-gradient(rgb(71, 38, 94), #9e0669f3);
  color: white;
  text-shadow: 1px 1px 2px black;
  font-weight: bold;
  border-style: outset;
  border: 2px solid silver;
}

.policy {
  text-shadow: 1px 2px 2px rgb(16, 148, 93);
  border-bottom: 2px solid white;
}

#bodyBox {
  background: -webkit-linear-gradient(rgb(3, 235, 243) 30%, #ad0472f3);
  box-shadow: 8px 5px 5px rgb(24, 23, 23);
  color: black;
  text-shadow: 1px 1px whitesmoke;
  font-weight: bold;
  font-size: 20px;
  border: 5px solid black;
  border-radius: 2%/2%;
  border-style: ridge;
  box-shadow: 10px 10px 10px rgb(46, 44, 44);
  /* font-family: "Arima Madurai", cursive; */
  margin: 15px;
}

#bodydivpic {
  max-width: 100%;
  max-height: 100%;
  width: 675px;
  height: auto;
  border: 3px solid rgba(39, 39, 39, 0.603);
  border-style: outset;
  background: -webkit-linear-gradient(
    left top,
    white,
    rgb(240, 182, 192),
    rgb(226, 108, 127) 100%
  );
  border-image-slice: 1 fill;
  margin-top: 10px;
  box-shadow: 10px 10px 10px rgb(46, 44, 44);
}

#bioText {
  margin: 0;
  color: white;
  text-shadow: 1px 1px 2px black;
  font-weight: bold;
  font-size: 18px;
  border: 4px solid rgba(58, 58, 58, 0.801);
  border-radius: 2%;
  border-style: outset;
  place-items: center;
  box-shadow: 10px 10px 10px rgb(24, 23, 23);
  background: -webkit-linear-gradient(rgb(3, 243, 163), #ad0472f3);
}

.carousel {
  border-radius: 1%;
  background-color: black;
  max-width: 640px;
  width: auto;
  position: relative;
  border: 9px solid;
  border-style: ridge;
  border-color: rgb(80, 80, 80);
  box-shadow: 8px 8px 10px rgb(31, 29, 29);
  box-sizing: border-box;
  max-height: 650px;
}

/* Carousel Images */
.carousel-item > img {
  width: 100%;
  height: auto;
}

/* Carousel Icons */
.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: 50px;
  width: 50px;
  outline: black;
  background-color: #610241;
  background-size: 50%, 50%;
  border-radius: 50%;
  border: 2px solid #9df5cc;
}

.capText {
  font-weight: bold;
  color: #9df5cc;
  font-size: 19px;
  text-shadow: 2px 2px 2px #f1069f;
}

#appButton {
  font-weight: bold;
  font-size: 18px;
  box-shadow: 4px 4px 5px lightgreen;
  text-shadow: 2px 2px 2px black;
  margin: 10px;
  border: 1px solid black;
  border-style: outset;
  border-radius: 5%;
  background-image: linear-gradient(
    45deg,
    rgb(3, 235, 243),
    rgb(3, 235, 243),
    #ad0472f3,
    #ad0472f3,
    #ad0472f3,
    rgb(3, 235, 243),
    rgb(3, 235, 243)
  );
  background-size: 600% 100%;
  animation-direction: alternate;
  animation: gradient 16s linear infinite;
  display: block;
}

@media only screen and (max-width: 680px) {
  #goneText {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  #goneText1 {
    display: none;
  }
}

.jumbotron {
  margin: 0;
  width: 100%;
  background-size: 600% 100%;
  background-attachment: fixed;
  background-image: radial-gradient(pink, whitesmoke,whitesmoke, pink);
  animation-direction: normal;
  animation: gradient 35s ease 1s infinite;
  /* font-family: "Comfortaa", cursive; */
  display: block;
  
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

/*<NAVBAR>*/
.navbar {
  font-family: "Arima Madurai", cursive;
  color: white;
  text-shadow: 2px 2px 2px #f1069f;
  background: black;
  border-bottom: 7px solid #7a0252;
  font-size: 20px;
  flex-direction: row;
  white-space: nowrap;
  text-shadow: 2px 2px 2px #f1069f;
  background: black;
}

/* Navbar Button */
.navbar-toggler {
  border: 5px solid #9df5cc;
  border-style: groove;
  box-shadow: 3px 3px 3px lightgreen;
  border-radius: 10%;
  background-color: #f1069f;
}

/* </NAVBAR>*/

/* Hover Glow */
.glow :hover {
  color: rgba(255, 255, 255, 0.856);
  /* text-align: center; */
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
      0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
      0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

#socialIcon {
  background: #007aff;
  color: white;
  padding: 10px;
  border-radius: 50%/50%;
  border-style: ridge;
  border: 3px solid white;
  text-shadow: 2px 2px 2px #f1069f;
}

/* <TABLE> */
Table {
  font-weight: bold;
  outline: 3px solid darkgray;
  outline-style: ridge;
  box-shadow: 10px 6px 5px greenyellow;
  width: auto;
}

#acrylicFills {
  background-image: linear-gradient(90deg, blueviolet, purple, cyan);
}
#sculpted {
  background-image: linear-gradient(90deg, yellow, limegreen, cyan);
}
#fullSets {
  background-image: linear-gradient(90deg, red, orange, cyan);
}
#pedicure {
  background-image: linear-gradient(90deg, pink, purple, cyan);
}
#addOns {
  background-image: linear-gradient(90deg, blue, grey, white);
}
#kids {
  background-image: linear-gradient(90deg, green, rebeccapurple, yellow);
}
#manicure {
  background-image: linear-gradient(90deg, black, gold, red);
}

#cost {
  text-align: center;
  color: white;
  text-shadow: 2px 2px black;
}

thead {
  text-shadow: 2px 2px black;
  text-align: center;
  font-size: 25px;
  font-style: italic;
}

#rowTitle {
  color: white;
  text-shadow: 1px 1px 2px blue;
}
#rowTitle1 {
  color: rgb(252, 82, 243);
  text-shadow: 1px 1px 2px blueviolet;
}
#rowTitle2 {
  color: yellow;
  text-shadow: 1px 1px 2px limegreen;
}
#rowTitle6 {
  color: rgb(209, 165, 207);
  text-shadow: 1px 1px 2px blueviolet;
}
#rowTitle3 {
  color: orange;
  text-shadow: 1px 1px 2px red;
}
#rowTitle4 {
  color: lightblue;
  text-shadow: 1px 1px 2px blue;
}
#rowTitle5 {
  color: greenyellow;
  text-shadow: 1px 1px 2px limegreen;
}
#rowTitle6 {
  color: gold;
  text-shadow: 1px 1px 2px limegreen;
}
/* </Table> */

.site-footer {
  color: #9df5cc;
  text-shadow: 2px 2px 2px #f1069f;
  border-top: 7px solid #610241;
  background-color: black;
  padding: 20px;
  font-family: "Arima Madurai", cursive;
  font-weight: bold;
  font-size: 18px;
  flex-direction: row;
  white-space: nowrap;
}

/* 
#specials {
  background: -webkit-linear-gradient(top, grey, black);
} */

/* .infoBox {
  color: white;
  font-weight: bold;
  font-size: 20px;
  border: 4px solid black;
  border-radius: 1%/1%;
  border-style: groove;
  box-shadow: 10px 10px 10px rgb(31, 29, 29);
  font-family: "Arima Madurai", cursive;
  text-shadow: 3px 3px 5px #2c2128b4;
} */
